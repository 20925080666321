import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

function ConfirmationModal({
  data,
  api,
  refresh,
  successMessage,
  show,
  onHide,
  content,
}) {
  const [loader, setLoader] = useState(false);

  const handleConfirmDelete = () => {
    setLoader(true);

    api(data?.id)
      .then((response) => {
        refresh();
        toast.success(successMessage);
        setLoader(false);
        onHide(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.data);
        setLoader(false);
        onHide(false);
      });
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
          <div className="popup-warning-icon">!</div>
          <h3>Are you sure?</h3>
          <p>{content}</p>
          <div className="pt-4 display-flex gap">
            <Button
              //   variant="danger"
              className="btn-sm btn-primary"
              onClick={handleConfirmDelete}
            >
              {loader ? <Spinner animation="border" size="sm" /> : "Yes"}
            </Button>
            <Button
              variant="secondary"
              className="btn-sm ml-2"
              onClick={() => onHide()}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
