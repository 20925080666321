import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { sendPointToLoyaltyProgramApi } from "../../services/Store/StoreServices";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function ViewOrderSummaryModal({
  show,
  onHide,
  selectedSummaryItems,
  totalPoints,
  userId,
}) {
  const [loader, setLoader] = useState(false);
  const storeId = useSelector((state) => state.auth.storeId);
  const { t } = useTranslation();

  async function handleSendPoints() {
    setLoader(true);
    try {
      const postData = {
        storeId: storeId,
        points: totalPoints,
        userId: userId,
      };
      const res = await sendPointToLoyaltyProgramApi(postData);
      toast.success(
        `${t("toast_messages.send_rewards_point_success_message")}`
      );
      onHide();
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }
  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="p-4">
        <h4>{t("summary_reward_form.title")}</h4>
        {selectedSummaryItems.map((item, index) => (
          <div
            key={item.id}
            className="p-2 d-flex align-items-start justify-content-between mb-2"
          >
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-start flex-column">
                <p style={{ fontWeight: "600", margin: "0" }}>{item.name}</p>
                <span>
                  {item?.points} {t("summary_reward_form.points")}
                </span>
              </div>
              <span>x{item?.quantity}</span>
            </div>
          </div>
        ))}
        <Modal.Footer></Modal.Footer>
        <div className="p-2 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-start flex-column">
            <p style={{ fontWeight: "600", margin: "0" }}>
              {t("summary_reward_form.total_points")}
            </p>
            <span>
              {totalPoints} {t("summary_reward_form.points")}
            </span>
          </div>
          <span>x{selectedSummaryItems?.length}</span>
        </div>
        <button
          className="btn btn-primary btn-sm w-100 mt-2"
          type="button"
          onClick={() => handleSendPoints()}
        >
          {loader ? (
            <Spinner animation="border" size="sm" />
          ) : (
            `${t("summary_reward_form.button_name")}`
          )}
        </button>
      </div>
    </Modal>
  );
}
