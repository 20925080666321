import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { getItemsApi } from "../../services/Store/StoreServices";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const SendPointsByQrCodeModal = ({
  show,
  onHide,
  setTotalSendPoints,
  setSelectedSummaryItems,
  setShowViewSummaryModal,
}) => {
  const [items, setItems] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [loader, setLoader] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pointsError, setPointsError] = useState("");
  const { t } = useTranslation();
  // const selectedSummaryItems = [];

  //=================GET TOTAL SUMMARY =================
  const handleSummaryModalOpen = () => {
    if (totalPoints < 1) {
      setPointsError(`${t("errors.points_error")}`);
      return;
    }
    const selectedSummaryItems = selectedItems.filter(
      (item) => item?.quantity > 0
    );
    setSelectedSummaryItems(selectedSummaryItems);
    setShowViewSummaryModal(true);
    onHide();
  };

  async function handleGetItems() {
    setLoader(true);
    try {
      const res = await getItemsApi();
      const fetchedItems = res?.data?.data?.items?.map((item) => ({
        name: item?.name,
        quantity: 0,
        points: 0,
      }));
      setSelectedItems(fetchedItems);
      setItems(res?.data?.data?.items);
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }
  const handleIncrement = (index) => {
    const newItems = [...selectedItems];
    newItems[index].quantity += 1;
    newItems[index].points = newItems[index].quantity * items[index].points;
    setSelectedItems(newItems);
    updateTotalPoints(newItems);
    setPointsError("");
  };

  const handleDecrement = (index) => {
    const newItems = [...selectedItems];
    if (newItems[index].quantity > 0) {
      newItems[index].quantity -= 1;
      newItems[index].points = newItems[index].quantity * items[index].points;
      setSelectedItems(newItems);
      updateTotalPoints(newItems);
    }
  };

  const updateTotalPoints = (items) => {
    const total = items.reduce((acc, item) => acc + item.points, 0);
    setTotalPoints(total);
    setTotalSendPoints(total);
  };

  useEffect(() => {
    handleGetItems();
  }, []);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("loyalty_rewards_form.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t("loyalty_rewards_form.label")}</h4>
        <InputGroup>
          <FormControl
            value={`${totalPoints} ${t("loyalty_rewards_form.placeholder")}`}
            readOnly
          />
        </InputGroup>
        <span className="text-danger mb-4">{pointsError}</span>
        {loader ? (
          <Spinner animation="border" />
        ) : (
          <div className="d-flex flex-column mt-3">
            {items.map((item, index) => (
              <div
                key={item.id}
                className="mb-3 d-flex align-items-start justify-content-between"
              >
                <p style={{ fontWeight: "600", margin: "0" }}>{item.name}</p>
                <div className="quantity-control d-flex align-items-center">
                  <Button
                    variant="outline-secondary btn-sm"
                    onClick={() => handleDecrement(index)}
                  >
                    -
                  </Button>
                  <span className="mx-2">{selectedItems[index]?.quantity}</span>
                  <Button
                    variant="outline-primary btn-sm"
                    onClick={() => handleIncrement(index)}
                  >
                    +
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn-sm" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          className="btn-sm"
          onClick={() => handleSummaryModalOpen()}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendPointsByQrCodeModal;
