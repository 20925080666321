import { z } from "zod";

export const addStoreValidation = z.object({
  storeName: z.string().trim().min(3, { message: "Name must be 3 characters" }),

  contactNumber: z
    .string()
    .trim()
    .min(6, { message: "phone must be 6 characters" })
    .max(15, {
      message: "phone must be lessThen 15 characters",
    }),

  description: z.string().min(1, { message: "description is required" }),
});

export const addOffersValidation = z.object({
  couponCode: z
    .string()
    .trim()
    .min(3, { message: "Name must be 3 characters" }),
  discountAmount: z
    .string()
    .trim()
    .min(1, { message: "Discount amount is required" }),
  minimumSpend: z
    .string()
    .trim()
    .min(1, { message: "Spend amount is required" }),
  eligibleItems: z
    .string()
    .trim()
    .min(3, { message: "Eligible Item can't be empty" }),
  termsCondition: z
    .string()
    .trim()
    .min(1, { message: "Terms & Conditions cant't be empty" }),
  startDate: z
    .string()
    .trim()
    .min(1, { message: "Start Date can't be empty`" }),
  expiresDate: z
    .string()
    .trim()
    .min(1, { message: "Expire Date can't be empty`" }),
});

export const addProgramValidation = z.object({
  name: z.string().trim().min(3, { message: "Name must be 3 characters" }),
  title: z.string().trim().min(3, { message: "Title must be 3 characters" }),
  description: z
    .string()
    .trim()
    .min(3, { message: "Description must be 3 characters" }),
});
export const addBrandValidation = z.object({
  name: z.string().trim().min(3, { message: "Name must be 2 characters" }),
  title: z.string().trim().min(3, { message: "Title must be 3 characters" }),
  description: z
    .string()
    .trim()
    .min(3, { message: "Description must be 3 characters" }),
  link: z.string().trim().min(0, { message: "Link is required " }),
});
