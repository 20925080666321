import React, { useEffect, useState } from "react";
import { getVendorPolicyApi } from "../../services/Store/StoreServices";
import { Spinner } from "react-bootstrap";

export default function Policy() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState("");

  function getTableData() {
    setLoader(true);
    getVendorPolicyApi()
      .then((response) => {
        setData(response?.data);

        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }
  //   console.log(data);

  useEffect(() => {
    getTableData();
  }, []);
  return (
    <div>
      {loader ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <div
          className="terms-section"
          dangerouslySetInnerHTML={{ __html: data }}
        />
      )}
    </div>
  );
}
