import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Card } from "react-bootstrap";

const StoreDetailsSkeleton = () => {
  return (
    <>
      <Card style={{ background: "" }}>
        <Card.Header className="d-block">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
            <div>
              <Skeleton circle={true} width={100} height={100} />
            </div>
            <div className="text-left" style={{ flex: "1" }}>
              <h3 className="text-color">
                <Skeleton width={140} height={20} />
              </h3>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="m-0">Created Date</h5>
                <p className="m-0">
                  <Skeleton width={100} height={20} />
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>Phone</h5>
                <p style={{ margin: "0" }}>
                  <Skeleton width={100} height={20} />
                </p>
              </div>
            </div>
          </div>
        </Card.Header>
      </Card>
      <div className="d-flex store-cards" style={{ gap: "20px" }}>
        <Card
          className="loyalty-program"
          style={{
            background: "#ffff",
            transform: "none",
            color: "#000",
          }}
        >
          <Card.Header>Documents</Card.Header>
          <div
            className="d-flex complaint-image"
            style={{ height: "125px", gap: "20px" }}
          >
            {[...Array(3)].map((_, index) => (
              <Skeleton key={index} width={120} height={100} />
            ))}
          </div>
        </Card>
        <Card
          className="loyalty-program"
          style={{
            transform: "none",
            background: "#ffff",
            cursor: "pointer",
            color: "#000",
          }}
        >
          <Card.Header>Loyalty Programs</Card.Header>
          <div className="loyalty-program-card" style={{ transform: "none" }}>
            <div className="program-card">
              <div className="card-img">
                <Skeleton width={50} height={50} />
              </div>
              <div>
                <h4>
                  <Skeleton width={200} height={20} />
                </h4>
                <p>
                  <Skeleton width={300} height={20} />
                </p>
              </div>
            </div>
            <p className="m-0">
              <Skeleton width={300} height={20} />
            </p>
          </div>
        </Card>
      </div>
      <Card>
        <div className="p-4">
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <h5 className="m-0">Open Time</h5>
            <p className="m-0">
              <Skeleton width={100} height={20} />
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <h5 className="m-0">Close Time</h5>
            <p className="m-0">
              <Skeleton width={100} height={20} />
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <h5 className="m-0">Address</h5>
            <p className="m-0 ">
              <Skeleton width={300} height={20} />
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center border-bottom">
            <h5 className="m-0">Description</h5>
            <p className="m-0 ">
              <Skeleton width={300} height={20} />
            </p>
          </div>
        </div>
      </Card>
    </>
  );
};

export default StoreDetailsSkeleton;
