import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { addTierApi } from "../../services/Store/StoreServices";
import toast from "react-hot-toast";
import TierDetails from "./TierDetails";
import { useTranslation } from "react-i18next";

export default function CreateRewardsTier({
  title,
  show,
  onHide,
  storeId,
  data,
}) {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [silverTier, setSolverTier] = useState({
    silver: data?.silver,
  });
  const [PlatinumTier, setPlatinumTier] = useState({
    platinum: data?.platinum,
  });
  const [goldTier, setGoldTier] = useState({
    gold: data?.gold,
  });
  const [diamondTier, setDiamondTier] = useState({
    diamond: data?.diamond,
  });

  async function handleCreateTier(e) {
    e.preventDefault();
    setLoader(true);

    try {
      const res = await addTierApi(
        silverTier,
        PlatinumTier,
        goldTier,
        diamondTier,
        storeId
      );
      toast.success(`${t("toast_messages.add_tier_success_message")}`);
      onHide();
      window.location.reload();
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="p-4">
        <h3 className="p-2"> {title}</h3>
        <form className="p-2" onSubmit={handleCreateTier}>
          <div className="tier-name-icon">
            <h4 className="m-0">{t("create_edit_tier_form.label.silver")} </h4>
          </div>
          <div className="form-group d-flex" style={{ gap: "10px" }}>
            <div style={{ flex: "1" }}>
              <input
                type="number"
                placeholder={t("create_edit_tier_form.placeholder.points")}
                className="form-control"
                required
                value={silverTier?.silver}
                onChange={(e) =>
                  setSolverTier({
                    ...silverTier,
                    silver: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="tier-name-icon">
            <h4 className="m-0">{t("create_edit_tier_form.label.gold")} </h4>
          </div>
          <div className="form-group d-flex" style={{ gap: "10px" }}>
            <div style={{ flex: "1" }}>
              {/* <label> Points</label> */}
              <input
                type="number"
                placeholder={t("create_edit_tier_form.placeholder.points")}
                className="form-control"
                required
                value={goldTier?.gold}
                onChange={(e) =>
                  setGoldTier({
                    ...goldTier,
                    gold: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="tier-name-icon">
            <h4 className="m-0">
              {t("create_edit_tier_form.label.platinum")}{" "}
            </h4>
          </div>
          <div className="form-group d-flex" style={{ gap: "10px" }}>
            <div style={{ flex: "1" }}>
              <input
                type="number"
                placeholder={t("create_edit_tier_form.placeholder.points")}
                className="form-control"
                required
                value={PlatinumTier?.platinum}
                onChange={(e) =>
                  setPlatinumTier({
                    ...PlatinumTier,
                    platinum: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="tier-name-icon">
            <h4 className="m-0">{t("create_edit_tier_form.label.diamond")} </h4>
          </div>
          <div className="form-group d-flex" style={{ gap: "10px" }}>
            <div style={{ flex: "1" }}>
              <input
                type="number"
                placeholder={t("create_edit_tier_form.placeholder.points")}
                className="form-control"
                required
                value={diamondTier?.diamond}
                onChange={(e) =>
                  setDiamondTier({
                    ...diamondTier,
                    diamond: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary btn-sm">
              {loader ? (
                <Spinner animation="border" size="sm" />
              ) : (
                `${t("create_edit_tier_form.button.saveRewardTier")}`
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
