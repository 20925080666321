import React from "react";
import "../SkeletonPages/CouponLoader.css";

const SkeletonLoader = ({ count }) => {
  const skeletonRows = [];
  for (let i = 0; i < count; i++) {
    skeletonRows.push(
      <div className="skeleton-card">
        <div className="skeleton-logo" />
        <div className="skeleton-dashed-line" />
        <div className="skeleton-description">
          <div className="skeleton-title" />
          <div className="skeleton-sub-title" />
          <div className="skeleton-valid-date" />
        </div>
      </div>
    );
  }
  return <>{skeletonRows}</>;
};

export default SkeletonLoader;
