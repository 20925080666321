import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { CHANGE_PASSWORD, DASHBOARD, LOGIN, LOGOUT } from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";
import swal from "sweetalert";

export function signUp(formData) {
  return axiosInstance.post(`user/auth/signUp`, formData);
}

export function login(email, password, roll) {
  const postData = {
    email,
    password,
    type: "Vendor",
  };

  return axiosInstance.post("user/auth/loginVendor", postData);
}

export function dashboard(filter) {
  return axiosInstance.get(DASHBOARD + `?data=${filter}`);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };
  return axiosInstance.put(CHANGE_PASSWORD, postData);
}
export async function logOutApi() {
  const response = await axiosInstance.post("user/auth/logout");
  return response;
}

export function formatError(errorMessage) {
  if (errorMessage) {
    swal("Oops", errorMessage, "error");
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("loyaltyToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("loyaltyToken");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    history.push("/");
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}
