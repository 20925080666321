import React, { useState, useEffect } from "react";
import { Card, Modal, Spinner } from "react-bootstrap";
import { addStoreValidation } from "../components/ZodSchemas/Schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import moment from "moment";
import AWS from "aws-sdk";
import { toast } from "react-hot-toast";
import { date } from "zod";
import { useTranslation } from "react-i18next";
import { countryCodesLabels } from "../common/Labels";
import axios from "axios";
import CustomDropdown from "../components/TimePickerInput";

export default function AddStore({
  title,
  show,
  onHide,
  list,
  api,
  item,
  successMessage,
  buttonTitle,
}) {
  const [loader, setLoader] = useState(false);
  const [showGooglePlaces, setShowGooglePlaces] = useState(!!!item?.address);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [openTimeError, setOpenTimeError] = useState("");
  const [closeTimeError, setCloseTimeError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [imageKeys, setImageKeys] = useState({
    image: "",
  });
  const [location, setLocation] = useState({
    address: "",
    lat: "",
    long: "",
  });
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState(item?.countryCode);

  const handleClick = () => {
    setShowGooglePlaces(true);
    setAddressError("");
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(addStoreValidation),
  });

  const handleAddress = (place) => {
    setLocation({ address: place?.label });

    geocodeByAddress(place.label)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((res) => {
        setLocation((prevLocation) => ({
          ...prevLocation,
          lat: res.lat,
          long: res.lng,
        }));
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
    setAddressError("");
  };

  //======================12 to 24 hours=======================

  async function onSubmit(data) {
    console.log(data);

    if (location?.address === "" || location.address === undefined) {
      setAddressError("Address is required");
      return;
    }
    if (openTime?.trim() === "") {
      setOpenTimeError("OpenTime is required");
      return;
    }
    if (closeTime?.trim() === "") {
      setCloseTimeError("CloseTime is required");
      return;
    }

    const formData = new FormData();
    if (title === "Update") {
      formData.append("image", imageKeys.image);
      formData.append("storeName", data.storeName);
      formData.append("contactNumber", data.contactNumber);
      formData.append("description", data.description);
      formData.append("openTime", openTime);
      formData.append("closeTime", closeTime);
      formData.append("address", location.address);
      formData.append("lat", location.lat);
      formData.append("long", location.long);
      formData.append("registrationId", "store");
      formData.append("storeId", item?.id);
      formData.append("countryCode", countryCode);
    } else {
      formData.append("image", imageKeys.image);
      formData.append("storeName", data.storeName);
      formData.append("contactNumber", data.contactNumber);
      formData.append("description", data.description);
      formData.append("openTime", openTime);
      formData.append("closeTime", closeTime);
      formData.append("address", location.address);
      formData.append("lat", location.lat);
      formData.append("long", location.long);
      formData.append("registrationId", "store");
      formData.append("countryCode", countryCode);
    }

    setLoader(true);
    try {
      const response = await api(formData);
      toast.success(successMessage);
      onHide();
      list();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    const fetchCountryCode = async () => {
      if (!navigator.geolocation) {
        console.log("Geolocation is not supported by this browser.");
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
            );

            if (response.data.status === "OK") {
              const results = response.data.results;
              if (results.length > 0) {
                const addressComponents = results[0].address_components;
                const countryComponent = addressComponents.find((component) =>
                  component.types.includes("country")
                );

                if (countryComponent) {
                  const countryCodeNumber = await countryCodesLabels
                    ?.countryCodeMapping[countryComponent.short_name];
                  setCountryCode(countryCodeNumber);
                } else {
                  console.log("Country code not found");
                }
              } else {
                console.log("No results found");
              }
            } else {
              console.log(`Error: ${response.data.status}`);
            }
          } catch (err) {
            console.log("Failed to fetch data");
          }
        },
        (error) => {
          console.log("Error getting location:", error.message);
        }
      );
    };
    if (!title === "Update") {
      fetchCountryCode();
    }
  }, []);

  useEffect(() => {
    if (item) {
      reset({
        storeName: item.storeName,
        contactNumber: item.contactNumber,
        description: item.description,
      });
      setImageKeys({
        image: item?.image,
      });
      setLocation({
        address: item?.address,
        lat: item?.location?.coordinates[0],
        long: item?.location?.coordinates[1],
      });
      setOpenTime(moment(item?.openTime, "HH:mm:ss").format("hh:mm A"));
      setCloseTime(moment(item?.closeTime, "HH:mm:ss").format("hh:mm A"));
    }
  }, [item, reset]);

  return (
    <Modal show={show} onHide={onHide} centered className="modal-style">
      <div className="store-modal">
        <h3>{title}</h3>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>{t("common.image")}</label>

            <input
              id="files"
              type="file"
              className="form-control"
              required={title === "Update" ? false : true}
              accept=".jpg,.png,.jpeg"
              onChange={(e) =>
                setImageKeys({
                  ...imageKeys,
                  image: e.target.files[0],
                })
              }
            />
            {item?.image && (
              <span className="">
                {item?.image?.length > 49
                  ? item?.image?.slice(0, 49) + ".."
                  : item?.image}
              </span>
            )}
          </div>

          <div className="form-group">
            <input
              id="storeName"
              type="text"
              placeholder={t("addEdit_store_form.store_name_placeholder")}
              className="form-control"
              {...register("storeName")}
            />
            {errors.storeName && (
              <p className="italic mt-1 m-0 text-danger text-left">
                {errors?.storeName?.message}
              </p>
            )}
          </div>
          <div className="form-group">
            {showGooglePlaces ? (
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                selectProps={{
                  placeholder: t(
                    "addEdit_store_form.store_address_placeholder"
                  ),
                  onChange: (place) => {
                    handleAddress(place);
                  },
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["gr", "cy"], // Restrict to Greece and Cyprus
                  },
                }}
              />
            ) : (
              <input
                type="text"
                className="form-control mt-2"
                value={location?.address}
                onClick={handleClick}
                placeholder="Display Address"
              />
            )}
            {addressError && (
              <span className="italic text-danger text-left">
                {addressError}
              </span>
            )}
          </div>

          <div className="form-group d-flex" style={{ gap: "10px" }}>
            <div style={{ flex: "1" }}>
              <label>{t("common.open_time")}</label>

              <CustomDropdown setValue={setOpenTime} value={openTime} />

              {openTimeError && (
                <p className="italic m-0 text-danger text-left">
                  {openTimeError}
                </p>
              )}
            </div>
            <div style={{ flex: "1" }}>
              <label>{t("common.close_time")}</label>

              <CustomDropdown setValue={setCloseTime} value={closeTime} />
              {closeTimeError && (
                <p className="italic m-0 text-danger text-left">
                  {closeTimeError}
                </p>
              )}
            </div>
          </div>
          <div className="form-group">
            <div className="d-flex" style={{ gap: "10px" }}>
              <PhoneInput
                className="p-0 conuntry-code"
                country={"cy"}
                enableSearch={false}
                value={`+${countryCode}`}
                onChange={(phone) => setCountryCode(`+${phone}`)}
              />
              <input
                id="contactNumber"
                type="number"
                placeholder={t("common.phone")}
                className="form-control"
                {...register("contactNumber")}
              />
            </div>
            {errors.contactNumber && (
              <p className="italic m-0 text-danger text-left">
                {errors?.contactNumber?.message}
              </p>
            )}
          </div>
          <div className="form-group">
            <textarea
              id="description"
              rows={5}
              type="text"
              placeholder={t("common.description")}
              className="form-control"
              {...register("description")}
            />
            {errors.description && (
              <p className="italic m-0 text-danger text-left">
                {errors?.description?.message}
              </p>
            )}{" "}
          </div>

          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary btn-sm">
              {loader ? <Spinner animation="border" size="sm" /> : buttonTitle}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
