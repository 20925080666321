import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/user-icon3.png";
import avatar from "../../../images/avatar/1.jpg";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import ChangePassword from "../../modal/ChangePassword";

const Header = ({ onNote, props }) => {
  const name = localStorage.getItem("name");
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [access, setAccess] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const status = localStorage.getItem("verifyStatus");

  useEffect(() => {
    const fetchData = () => {
      try {
        const permissions = localStorage.getItem("role");
        if (permissions) {
          setAccess(JSON.parse(permissions));
        } else {
          console.error("Permissions data not found in local storage");
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    fetchData();

    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");
    const toggleFunc = () => aaa.classList.toggle("menu-toggle");

    btn.addEventListener("click", toggleFunc);

    return () => {
      btn.removeEventListener("click", toggleFunc);
    };
  }, []);

  return (
    <>
      <ChangePassword
        password={changePassword}
        onHide={() => setChangePassword(false)}
      />
      <div className="header" style={{ zIndex: "11" }}>
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left"></div>
              <ul className="navbar-nav header-right">
                <Dropdown as="li" className="nav-item dropdown header-profile">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="i-false c-pointer nav-link"
                    to=""
                    role="button"
                    data-toggle="dropdown"
                  >
                    <div className="header-info">
                      <span>
                        {t("navbar_menu.hello")}, <strong>{name}</strong>
                      </span>
                    </div>
                    <img src={profile} width={20} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    align="right"
                    className="dropdown-menu dropdown-menu-right"
                  >
                    {status === "Accepted" ? (
                      <>
                        {access === "Vendor" && (
                          <Link
                            to="/vendor-profile"
                            className="dropdown-item ai-icon"
                          >
                            <svg
                              id="icon-user1"
                              xmlns="http://www.w3.org/2000/svg"
                              className="text-primary"
                              width={18}
                              height={18}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                              <circle cx={12} cy={7} r={4} />
                            </svg>
                            <span className="ml-2">
                              {t("navbar_menu.profile")}{" "}
                            </span>
                          </Link>
                        )}

                        <Link
                          className="dropdown-item ai-icon"
                          onClick={() => setChangePassword(true)}
                          style={{ backgroundColor: "#fff" }}
                        >
                          <svg
                            id="icon-password"
                            xmlns="http://www.w3.org/2000/svg"
                            className="text-primary"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                            <polyline points="16 17 21 12 16 7" />
                            <line x1={21} y1={12} x2={9} y2={12} />
                          </svg>
                          <span className="ml-2">
                            {t("navbar_menu.change_password")}
                          </span>
                        </Link>
                        {access === "Vendor" && (
                          <>
                            <Link
                              to="/terms-conditions"
                              className="dropdown-item ai-icon"
                              style={{ backgroundColor: "#fff" }}
                            >
                              <svg
                                id="icon-password"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-primary"
                                width={18}
                                height={18}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                <polyline points="16 17 21 12 16 7" />
                                <line x1={21} y1={12} x2={9} y2={12} />
                              </svg>
                              <span className="ml-2">
                                {t("navbar_menu.terms_condition")}
                              </span>
                            </Link>
                            <Link
                              to="/privacy-policy"
                              className="dropdown-item ai-icon"
                              style={{ backgroundColor: "#fff" }}
                            >
                              <svg
                                id="icon-password"
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-primary"
                                width={18}
                                height={18}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                <polyline points="16 17 21 12 16 7" />
                                <line x1={21} y1={12} x2={9} y2={12} />
                              </svg>
                              <span className="ml-2">
                                {t("navbar_menu.privacy-policy")}
                              </span>
                            </Link>
                          </>
                        )}
                        <LogoutPage />
                        <div className="language-translate-btn p-0">
                          {i18n.language === "en" ? (
                            <Link
                              className="dropdown-item ai-icon"
                              onClick={() => changeLanguage("gr")}
                              style={{ backgroundColor: "#fff" }}
                            >
                              <i className="flaticon-381-internet"></i>
                              <span className="ml-2">{t("Ελληνικά")}</span>
                            </Link>
                          ) : (
                            <Link
                              className="dropdown-item ai-icon"
                              onClick={() => changeLanguage("en")}
                              style={{ backgroundColor: "#fff" }}
                            >
                              <i className="flaticon-381-internet"></i>
                              <span className="ml-2">{t("English")}</span>
                            </Link>
                          )}
                        </div>
                      </>
                    ) : (
                      <LogoutPage />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
