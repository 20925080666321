import React from "react";
import "./CouponCard.css";
import moment from "moment";
import dashImage from "../../images/Vector 1.jpg";
import { Badge } from "react-bootstrap";
import defaultImage from "../../images/emptyImg.jpg";

const CouponCard = ({ data, fun }) => {
  function handleErrorImage(e) {
    e.target.src = defaultImage;
  }
  return (
    <div className="coupon-card mt-3">
      <div className="circle-left" />
      <div className="circle-right" />
      <img
        src={`https://api.paybagapp.com/${data?.logo}`}
        alt="store logo"
        className="store-logo"
        onError={handleErrorImage}
      />
      <img src={dashImage} alt="dashed line" className="dashed-line" />
      <div className="description">
        <div className="d-flex justify-content-between flex-wrap w-100">
          <p className="title">
            {data?.title?.length > 10
              ? data?.title?.slice(0, 10) + ".."
              : data?.title}
          </p>
          {data?.isRedeem ? (
            <Badge
              variant="danger light"
              className="d-flex align-items-center "
            >
              {"Expired"}
            </Badge>
          ) : data?.isExpire ? (
            <Badge
              variant="danger light"
              className="d-flex align-items-center "
            >
              {"Expired"}
            </Badge>
          ) : (
            <Badge
              variant="success light"
              className="d-flex align-items-center hover-scale"
              onClick={() =>
                data?.isRedeem
                  ? undefined
                  : fun(data?.id, "coupon", data?.pointNeeded)
              }
            >
              {"Redeem"}
            </Badge>
          )}
        </div>
        <p className="valid-date">Times per user: {data?.oncePerUser}</p>
        <p className="valid-date">Available for: {data?.availableNumber}</p>

        <div className="d-flex align-items-center justify-content-between w-100">
          <p className="valid-date">
            Valid until: {moment(data?.validDate).format("Do MMMM YYYY")}
          </p>
          <p className="sub-title">Point prices: {data?.pointPrice}</p>
        </div>
      </div>
    </div>
  );
};

export default CouponCard;
