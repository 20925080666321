import React, { useState } from "react";
import CodeGenerate from "../modal/CodeGenerate";

export default function EmployeeCodes() {
  const [addAccessCodeModal, setAccessCodeModal] = useState(false);
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <h3>Employee Code</h3>
        </div>
        <div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setAccessCodeModal(true)}
          >
            Generate Code
          </button>
        </div>
      </div>
      <div>EmployeeCodes</div>
      {addAccessCodeModal && (
        <CodeGenerate
          show={addAccessCodeModal}
          handleClose={() => setAccessCodeModal(false)}
          api={""}
        />
      )}
    </div>
  );
}
