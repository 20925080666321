import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { postNotificationApi } from "../../services/User/UserService";
import { useTranslation } from "react-i18next";
import { getVendorProfileApi } from "../../services/Store/StoreServices";

export default function NotificationManagements() {
  let errorsObj = { title: "", message: "", type: "" };
  const formRef = useRef(null);
  const [errors, setErrors] = useState(errorsObj);
  const [vendorData, setVendorData] = useState();
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    message: "",
  });

  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();

  async function handleVendorProfileData() {
    setLoader(true);
    try {
      const response = await getVendorProfileApi();
      setVendorData(response?.data?.data);
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (formData?.title?.trim() === "") {
      errorObj.title = t("notification_management.errors.title_required");
      error = true;
    }
    if (formData?.message?.trim() === "") {
      errorObj.message = t("notification_management.errors.body_required");
      error = true;
    }
    if (vendorData?.sendNotificationCount === 5) {
      toast.error(t("notification_management.errors.notification_limit"));
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    const postData = {
      title: formData?.title,
      description: formData?.message,
      tier: formData?.type,
    };
    try {
      const response = await postNotificationApi(postData);
      toast.success(t("notification_management.success.sent"));
      setFormData({ title: "", type: "", message: "" });
      if (formRef.current) {
        formRef.current.reset();
      }
      handleVendorProfileData();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(
        error?.response?.data?.data
          ? error?.response?.data?.data
          : error?.response?.data?.message
      );
    }
  };

  useEffect(() => {
    handleVendorProfileData();
  }, []);

  return (
    <div>
      <PageTitle
        activeMenu={t("notification_management.page_title.active")}
        motherMenu={t("notification_management.page_title.mother")}
      />
      <div className="col-lg-8 col-md-7 pl-0">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">
              {t("notification_management.form.title")}
            </h3>
          </div>
          <form onSubmit={onSubmit} ref={formRef}>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">
                  {t("notification_management.form.title_label")}
                </strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={formData.title}
                onChange={handleInput}
                placeholder={t(
                  "notification_management.form.title_placeholder"
                )}
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">
                  {t("notification_management.form.message_label")}
                </strong>
              </label>
              <textarea
                rows={5}
                className="form-control"
                name="message"
                value={formData.message}
                onChange={handleInput}
                placeholder={t(
                  "notification_management.form.message_placeholder"
                )}
              />
              {errors.message && (
                <div className="text-danger fs-12">{errors.message}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">
                  {t("notification_management.form.tier_label")}
                </strong>
              </label>
              <select
                className="form-control"
                name="type"
                value={formData.type}
                onChange={handleInput}
                required
              >
                <option value={""} disabled>
                  {t("common.select")}
                </option>
                <option value="all">
                  {t("notification_management.form.tier_options.all")}
                </option>
                <option value="silver">
                  {t("notification_management.form.tier_options.silver")}
                </option>
                <option value="gold">
                  {t("notification_management.form.tier_options.gold")}
                </option>
                <option value="platinum">
                  {t("notification_management.form.tier_options.platinum")}
                </option>
                <option value="diamond">
                  {t("notification_management.form.tier_options.diamond")}
                </option>
              </select>
              {errors.type && (
                <div className="text-danger fs-12">{errors.type}</div>
              )}
            </div>
            <div className="request-section-car pb-2">
              <p className="m-0 text-left" style={{ color: "#042e77" }}>
                {t("notification_management.status.used")}{" "}
                <span>{vendorData?.sendNotificationCount}</span>{" "}
                {t("notification_management.status.out_of")} <span>5</span>.
              </p>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loader}
              >
                {loader ? (
                  <>
                    <Spinner animation="border" size="sm" />{" "}
                    {t("notification_management.form.send")}
                  </>
                ) : (
                  t("notification_management.form.send")
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
