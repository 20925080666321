import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoyaltyItemSkeleton = ({ count }) => {
  const skeletonItems = [];
  for (let i = 0; i < count; i++) {
    skeletonItems.push(
      <div
        className="loyaltyItem mt-3"
        key={i}
        style={{ backgroundColor: "rgb(209 209 209 / 95%)" }}
      >
        <div className="d-flex justify-content-between">
          <h2 className="headerText">
            <Skeleton width={140} height={20} duration={0.8} />
          </h2>
          <Skeleton width={50} height={20} duration={0.8} />
        </div>
        <p className="subheaderText">
          Valid from : <Skeleton width={100} height={20} duration={0.8} />
        </p>
        <Skeleton className="darkVie" height={60}>
          <div style={{ justifyContent: "space-between" }}>
            <p className="tillText">Validity Till</p>
            <p className="validText">
              <Skeleton width={100} height={20} duration={0.8} />
            </p>
          </div>
          <div
            style={{
              width: 44,
              height: 44,
              borderRadius: 22,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Skeleton width={40} height={40} duration={0.8} />
          </div>
        </Skeleton>
      </div>
    );
  }

  return <>{skeletonItems}</>;
};

export default LoyaltyItemSkeleton;
