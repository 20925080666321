import React, { useEffect, useState } from "react";
import QRCodeScanner from "../components/QrCodeSection/QrCodeScanner";
import SendPointsByQrCodeModal from "../modal/SendPointsByQrCodeModal";
import ViewOrderSummaryModal from "../modal/ViewOrderSummaryModal";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function ScanQrCodeManagement() {
  const [showSendPointsModal, setShowSendPointsModal] = useState(false);
  const [showViewSummaryModal, setShowViewSummaryModal] = useState(false);
  const [totalSendPoints, setTotalSendPoints] = useState("");
  const [scannedQrCodeData, setScannedQrCodeData] = useState("");
  const [selectedSummaryItems, setSelectedSummaryItems] = useState([]);
  const { t } = useTranslation();

  function openSendPointsModal() {
    if (scannedQrCodeData?.id) {
      setShowSendPointsModal(true);
    } else {
      toast.error(`${t("toast_messages.valid_qr_code_message")}`);
    }
  }

  return (
    <div>
      <div className="">
        <QRCodeScanner
          setScanResult={setScannedQrCodeData}
          openSendPointsModal={openSendPointsModal}
        />
      </div>
      {showSendPointsModal && (
        <SendPointsByQrCodeModal
          show={showSendPointsModal}
          onHide={() => setShowSendPointsModal(false)}
          setTotalSendPoints={setTotalSendPoints}
          setSelectedSummaryItems={setSelectedSummaryItems}
          setShowViewSummaryModal={setShowViewSummaryModal}
        />
      )}
      {showViewSummaryModal && (
        <ViewOrderSummaryModal
          show={showViewSummaryModal}
          onHide={() => setShowViewSummaryModal(false)}
          totalPoints={totalSendPoints}
          selectedSummaryItems={selectedSummaryItems}
          userId={scannedQrCodeData?.id}
        />
      )}
    </div>
  );
}
