import React, { useState, useEffect } from "react";
import "./TimeDropdown.css";

const generateHourOptions = () => {
  const hours = [];
  for (let hour = 1; hour <= 12; hour++) {
    hours.push(String(hour).padStart(2, "0"));
  }
  return hours;
};

const generateMinuteOptions = () => {
  const minutes = [];
  for (let minute = 0; minute < 60; minute += 5) {
    minutes.push(String(minute).padStart(2, "0"));
  }
  return minutes;
};

const CustomDropdown = ({ setValue, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  console.log(value);
  const hourOptions = generateHourOptions();
  const minuteOptions = generateMinuteOptions();
  const periodOptions = ["AM", "PM"];

  useEffect(() => {
    if (value) {
      const [time, period] = value.split(" ");
      const [hour, minute] = time.split(":");
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setSelectedPeriod(period);
    }
  }, [value]);

  const handleHourClick = (hour) => {
    setSelectedHour(hour);
    checkIfComplete(hour, selectedMinute, selectedPeriod);
  };

  const handleMinuteClick = (minute) => {
    setSelectedMinute(minute);
    checkIfComplete(selectedHour, minute, selectedPeriod);
  };

  const handlePeriodClick = (period) => {
    setSelectedPeriod(period);
    checkIfComplete(selectedHour, selectedMinute, period);
  };

  const checkIfComplete = (hour, minute, period) => {
    if (hour && minute && period) {
      setIsOpen(false);
      const formattedTime = `${hour}:${minute} ${period}`;
      setValue(formattedTime);
    }
  };

  return (
    <div className="dropdown-container">
      <div className="custom-dropdown">
        <div className="dropdown-section" onClick={() => setIsOpen(!isOpen)}>
          {selectedHour && selectedMinute && selectedPeriod
            ? `${selectedHour}:${selectedMinute} ${selectedPeriod}`
            : "Select Time"}
          <span className={`dropdown-arrow ${isOpen ? "open" : ""}`}>▼</span>
        </div>
        {isOpen && (
          <div className="dropdown-content">
            <ul className="dropdown-list">
              {hourOptions.map((hour, index) => (
                <li
                  key={index}
                  className={`dropdown-options ${
                    selectedHour === hour ? "selected" : ""
                  }`}
                  onClick={() => handleHourClick(hour)}
                >
                  {hour}
                </li>
              ))}
            </ul>
            <ul className="dropdown-list minute-list">
              {minuteOptions.map((minute, index) => (
                <li
                  key={index}
                  className={`dropdown-options ${
                    selectedMinute === minute ? "selected" : ""
                  }`}
                  onClick={() => handleMinuteClick(minute)}
                >
                  {minute}
                </li>
              ))}
            </ul>
            <ul className="dropdown-list period-list">
              {periodOptions.map((period, index) => (
                <li
                  key={index}
                  className={`dropdown-options ${
                    selectedPeriod === period ? "selected" : ""
                  }`}
                  onClick={() => handlePeriodClick(period)}
                >
                  {period}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
