export const GET_STORE = "vendor/getMyStore";
export const ADD_STORE = "vendor/createStore";
export const EDIT_STORE = "vendor/editStore";
export const DELETE_STORE = "vendor/updateStore";

export const ADD_PROGRAM = "vendor/createLoyaltyProgram";

//=================Offers Apis =============================
export const GET_OFFERS = "vendor/getOffer";
export const ADD_OFFER = "vendor/createOffer";
export const EDIT_OFFER = "vendor/editOffer";
