import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { addProgramValidation } from "../components/ZodSchemas/Schemas";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function AddLoyaltyProgramModal({
  show,
  onHide,
  title,
  api,
  successMessage,
  storeId,
  programData,
}) {
  const [loader, setLoader] = useState(false);
  const [threadPoints, setThreadPoints] = useState([0]);
  const [offers, setOffers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const handleAddInput = () => {
    if (threadPoints.length < 5) {
      setThreadPoints([...threadPoints, Math.max(...threadPoints) + 1]);
      setOffers([...offers, ""]);
      setErrorMessage("");
    }
  };

  const handleCreateNewInput = (index, value) => {
    const updatedThreadPoints = [...threadPoints];
    updatedThreadPoints[index] = parseInt(value);
    setThreadPoints(updatedThreadPoints);

    const prevThreadPoints = index === 0 ? [] : threadPoints.slice(0, index);
    const isGreaterThanPrev = prevThreadPoints.every(
      (prev) => parseInt(value) > prev
    );
    if (!isGreaterThanPrev) {
      setErrorMessage(
        "New thread point must be greater than the previous thread points."
      );
    } else {
      setErrorMessage("");
    }
  };

  const handleOfferChange = (index, value) => {
    const updatedOffers = [...offers];
    updatedOffers[index] = value;
    setOffers(updatedOffers);
  };

  console.log(offers);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(addProgramValidation),
  });

  useEffect(() => {
    if (programData) {
      const threadPointsData = [
        programData.milestone1,
        programData.milestone2,
        programData.milestone3,
        programData.milestone4,
        programData.milestone5,
      ];
      const offersData = [
        programData.milestone1Offer,
        programData.milestone2Offer,
        programData.milestone3Offer,
        programData.milestone4Offer,
        programData.milestone5Offer,
      ];
      setThreadPoints(threadPointsData);
      setOffers(offersData);
    }
  }, [programData]);
  useEffect(() => {
    if (programData) {
      reset({
        name: programData.name,
        title: programData.title,

        description: programData.description,
      });
    }
  }, [programData, reset]);

  async function onSubmit(data) {
    setLoader(true);
    let formData = {};
    if (title === "Add Loyalty Program") {
      formData = {
        name: data.name,
        title: data.title,
        storeId: storeId,
        description: data.description,
        storeId: storeId,
      };
    } else {
      formData = {
        name: data?.name,
        title: data.title,
        programId: programData?.id,
        storeId: storeId,
        description: data.description,
      };
    }
    try {
      const response = await api(formData);
      toast.success(successMessage);
      onHide();
      window.location.reload();
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="m-0 p-2">{title}</h3>
          <div className="form-group">
            <input
              id="name"
              type="text"
              placeholder={t("common.name")}
              className="form-control"
              {...register("name")}
            />
            {errors.name && (
              <p className="italic mt-2 text-danger">{errors?.name?.message}</p>
            )}
          </div>
          <div className="form-group">
            <input
              id="title"
              type="text"
              placeholder={t("add_edit_loyalty_program_form.placeholder_one")}
              className="form-control"
              {...register("title")}
            />
            {errors.title && (
              <p className="italic mt-2 text-danger">
                {errors?.title?.message}
              </p>
            )}
          </div>
          {/* <label>{t("add_edit_loyalty_program_form.label")}</label> */}
          {/* {threadPoints.map((value, index) => (
            <>
              <div
                className="d-flex align-items-center mt-3"
                style={{ gap: "10px" }}
              >
                <div
                  key={index}
                  className="form-group  d-flex "
                  style={{ flex: "1" }}
                >
                  <input
                    type="number"
                    required
                    placeholder={t(
                      "add_edit_loyalty_program_form.placeholder_third"
                    )}
                    className="form-control"
                    value={threadPoints[index]}
                    onChange={(e) =>
                      handleCreateNewInput(index, e.target.value)
                    }
                  />
                </div>
                <div
                  className="form-group"
                  style={{ flex: "1", position: "relative" }}
                >
                  <input
                    id="description"
                    type="text"
                    placeholder={t(
                      "add_edit_loyalty_program_form.placeholder_second"
                    )}
                    required
                    className="form-control"
                    value={offers[index]}
                    onChange={(e) => handleOfferChange(index, e.target.value)}
                  />
                  {index !== 0 && (
                    <div className="remove-btn">
                      <button onClick={() => handleRemoveInput(index)}>
                        X
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {errorMessage && index === threadPoints.length - 1 && (
                <p className="italic text-danger">{errorMessage}</p>
              )}
            </>
          ))}
          <div className="d-flex justify-content-end mb-2">
            <button
              className="btn btn-primary btn-sm"
              onClick={handleAddInput}
              type="button"
            >
              {t("common.add")}
            </button>
          </div> */}

          <div className="form-group">
            <textarea
              id="description"
              type="text"
              placeholder={t("common.description")}
              className="form-control"
              {...register("description")}
              style={{ minHeight: "100px" }}
            />
            {errors.description && (
              <p className="italic mt-2 text-danger">
                {errors?.description?.message}
              </p>
            )}
          </div>

          <div className="add-program">
            <button className="btn btn-primary btn-sm">
              {loader ? (
                <Spinner animation="border" size="sm" />
              ) : (
                `${t("common.save")}`
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
