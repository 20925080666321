import React, { useEffect, useState } from "react";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import "./QrCodeScanner.css";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useTranslation } from "react-i18next";

const QRCodeScanner = ({ setScanResult, openSendPointsModal }) => {
  const [showScanner, setShowScanner] = useState(false);
  const { t } = useTranslation();

  const handleScan = (data) => {
    if (data) {
      setScanResult(JSON.parse(data));
      setShowScanner(false);
      openSendPointsModal();
    }
  };

  const handleError = (error) => {
    console.error("QR Scanner Error:", error);
  };

  return (
    <div className="qr-scanner-container">
      {!showScanner ? (
        <div className="camera-icon" onClick={() => setShowScanner(true)}>
          <MdOutlineQrCodeScanner size={150} />
          <span>{t("common.qr_code_title")}</span>
        </div>
      ) : (
        <div className="qr-scanner">
          <QrScanner onDecode={handleScan} onError={handleError} />
        </div>
      )}
    </div>
  );
};

export default QRCodeScanner;
