import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

/// App

/// Product List

/// Charts

/// Bootstrap

/// Table

/// Form

/// Pages

// import SignUp from "./pages/SignUp";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import UserManagement from "./pages/UserManagement";
import UserDetails from "./pages/UserDetails";
import StoreManagement from "./pages/StoreManagenent";
import TermsConditions from "./pages/TermsConditions";
import StoresDetails from "./pages/StoresDetails";
import LoyaltyProgramOffers from "./pages/LoyaltyProgramOffers";
import EmployeeCodes from "./pages/EmployeeCodes";
import ScanQrCodeManagement from "./pages/ScanQrCodeManagement";
import SubVendorManagement from "./pages/SubVendorManagement";
import CouponVerificationManagement from "./pages/CouponVerificationManagement";
import VendorProfileDetails from "./pages/VendorProfileDetails";
import NotificationManagements from "./pages/NotificationManagement";
import Policy from "./pages/Policy";

// import Documents from "./pages/Documents";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    // { url: "documents", component: Documents },
    /// User management

    { url: "user-management", component: UserManagement },
    { url: "user-details", component: UserDetails },
    { url: "store-management", component: StoreManagement },
    { url: "terms-conditions", component: TermsConditions },
    { url: "privacy-policy", component: Policy },

    { url: "store-details", component: StoresDetails },
    { url: "program-offers", component: LoyaltyProgramOffers },
    { url: "employee-code", component: EmployeeCodes },
    { url: "qrCode-management", component: ScanQrCodeManagement },
    { url: "subVendor-management", component: SubVendorManagement },
    { url: "coupon-verification", component: CouponVerificationManagement },
    { url: "vendor-profile", component: VendorProfileDetails },
    { url: "notification-management", component: NotificationManagements },

    // { url: "page-signup", component: SignUp },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
