import { lazy, Suspense, useEffect, useState } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import AWS from "aws-sdk";
import { CognitoIdentityCredentials } from "aws-sdk";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./jsx/css/Theme.css";
import "./jsx/common/Global.css";
import Documents from "./jsx/pages/Documents";

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
const SignUp = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/SignUp")), 500);
  });
});
function App(props) {
  AWS.config.update({
    region: process.env.REACT_APP_REGION,
    credentials: new CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_POOL_ID,
    }),
  });
  let path = window.location.pathname;

  // console.log(path);
  // if (path === "/login") {
  //   setLandingModal(true);
  // }

  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch, props.history);
  }, [dispatch, props.history]);
  let routes = (
    <Switch>
      <Route path="/signup" component={SignUp} />
      <Route path="/" component={Login} />
    </Switch>
  );

  // if (props.isAuthenticated && path === "/documents-verify") {
  //   return (
  //     <Switch>
  //       <Route path="/documents-verify" component={Documents} />
  //     </Switch>
  //   );
  // } else
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
        {/* <LandingModal
          show={landingModal}
          onHide={() => setLandingModal(false)}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
