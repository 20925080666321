import React, { useEffect, useState } from "react";
import "../pages/CouponManagement.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import moment from "moment";
import CutleryIcon from "../../images/shoppingBag.png";
import CouponCard from "../components/CouponCard";
import {
  verifyCouponCodeApi,
  getOfferCouponListApi,
  redeemOffersApi,
} from "../../services/Store/StoreServices";
import toast from "react-hot-toast";
import { Badge, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../components/SkeletonPages/CouopCardSkeleton";
import Lottie from "lottie-react";
import animationData from "../../images/pending.json";
import clockAnimation from "../../images/expireClock.json";
import axios from "axios";

import { countryCodesLabels } from "../common/Labels";
import { useSelector } from "react-redux";

const CouponVerificationManagement = () => {
  const [countryCode, setCountryCode] = useState("357");
  const [phone, setPhone] = useState("");
  const [filter, setFilter] = useState("offers");
  const [loader, setLoader] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offerData, setOfferData] = useState();
  const storeIde = localStorage.getItem("storeId");
  const [requestId, setRequestId] = useState("");
  const storeKey = useSelector((state) => state?.auth?.storeId);
  const role = localStorage.getItem("role");

  const { t } = useTranslation();

  const FilterOption = [
    {
      value: "offers",
      label: "Offers",
    },
    {
      value: "coupon",
      label: "Coupon",
    },
  ];

  async function handleSendRequest(e) {
    e.preventDefault();
    if (storeIde === "undefined" && storeKey === "") {
      toast.error("Store not created yet");
      return;
    }
    if (countryCode === "") {
      toast.error("countryCode is required");
      return;
    }
    if (phone.trim() === "") {
      toast.error("Phone is required");
      return;
    } else if (phone.trim().length < 6) {
      toast.error("phone should be minimum 6 number");
      return;
    }

    setLoader(true);
    try {
      const postData = {
        countryCode: `${countryCode}`,
        phoneNumber: phone,
        storeId: storeKey ? storeKey : storeIde,
      };
      if (role === "Vendor") {
        postData.type = "Vendor";
      }
      const response = await verifyCouponCodeApi(postData);
      setRequestData(response?.data?.data);
      setRequestId(response?.data?.data?.id);

      getOffersList(response?.data?.data?.id);
    } catch (error) {
      toast.error(
        error?.response?.data?.data
          ? error?.response?.data?.data
          : error?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }

  async function getOffersList(requestIdToUse) {
    setIsLoading(true);
    try {
      const idToUse = requestIdToUse || requestId;
      const response = await getOfferCouponListApi(idToUse, filter);
      setOfferData(response?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data?.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleVerifyCoupon(id, type, pointNeeded) {
    setIsLoading(true);
    try {
      const postData = {
        id: id,
        type: type,
        requestId: requestId,
        credit: pointNeeded === "Credit" ? true : false,
      };
      const response = await redeemOffersApi(postData);
      getOffersList();
      toast.success(`Verified ${type} successfully`);
    } catch (error) {
      toast.error(
        error?.response?.data?.data
          ? error?.response?.data?.data
          : error?.response?.data?.message
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (requestId && offerData?.message === "Pending") {
      const startTime = Date.now();

      const fetchOffers = () => {
        if (Date.now() - startTime >= 60000) {
          clearInterval(interval);
          setOfferData((prev) => ({ ...prev, message: "expired" }));
          setRequestId("");
          return;
        }
        getOffersList();
      };

      const interval = setInterval(fetchOffers, 2000);

      return () => clearInterval(interval);
    }
  }, [offerData?.message, requestId]);

  useEffect(() => {
    if (filter && requestId && offerData?.message === "Accept") {
      getOffersList();
    }
  }, [filter, offerData?.message, requestId]);
  return (
    <div className="coupons">
      <div className="search-container">
        <div className="input-section">
          <PhoneInput
            enableSearch={false}
            country={"cy"}
            disabled
            value={`+${countryCode}`}
            onChange={(phone) => setCountryCode(phone)}
          />
          <input
            type="number"
            id="phone"
            placeholder={t("coupon_management.placeholder")}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <button
          onClick={handleSendRequest}
          className="hover-scale"
          disabled={offerData?.message === "Pending" ? true : false}
        >
          {loader ? (
            <Spinner animation="border" size="sm" />
          ) : (
            `${t("coupon_management.button_name")}`
          )}
        </button>
      </div>

      <div className="listing-section">
        {offerData?.data && (
          <div className="filter-section">
            <div className="filter-tabs">
              {FilterOption.map((option, index) => (
                <p
                  key={index}
                  onClick={() => setFilter(option.value)}
                  className={`${
                    option.value === filter ? "active-tab" : "tab"
                  }`}
                >
                  {option.label}
                </p>
              ))}
            </div>
          </div>
        )}

        {offerData?.message === "Pending" ? (
          <div className="pt-4">
            <h4 className="text-center m-0">
              {t("coupon_management.waiting_status")}
            </h4>
            <Lottie
              style={{
                height: "200px",
                paddingBottom: "0",
              }}
              animationData={animationData}
            />
          </div>
        ) : offerData?.message == "Accept" ? (
          <>
            {isLoading ? (
              <div className="d-flex justify-content-center flex-column">
                <SkeletonLoader count={3} />
              </div>
            ) : (
              <div className="mt-3">
                {filter === "offers" ? (
                  <>
                    {offerData?.data?.map((offer, i) => (
                      <div
                        className="loyaltyItem mt-3  "
                        key={i}
                        style={{
                          backgroundColor:
                            offer?.pointNeeded === "Debit" && "#fee6ea",
                        }}
                      >
                        <div className="d-flex justify-content-between w-100">
                          <p className="title">{offer?.title}</p>
                          {offer?.isRedeem ? (
                            <Badge
                              variant="danger light"
                              className="d-flex align-items-center "
                            >
                              {"Expired"}
                            </Badge>
                          ) : offer?.isExpire ? (
                            <Badge
                              variant="danger light"
                              className="d-flex align-items-center "
                            >
                              {"Expired"}
                            </Badge>
                          ) : (
                            <Badge
                              variant="success light"
                              className="d-flex align-items-center hover-scale"
                              onClick={() =>
                                offer?.isRedeem
                                  ? undefined
                                  : handleVerifyCoupon(
                                      offer?.id,
                                      "offer",
                                      offer?.pointNeeded
                                    )
                              }
                            >
                              {"Redeem"}
                            </Badge>
                          )}
                        </div>
                        <p className="subheaderText text-left pb-0">
                          {t("offer_card.h1")}: {offer?.oncePerUser}
                        </p>
                        <div className="d-flex justify-content-between mb-2">
                          <p className="subheaderText text-left m-0">
                            {t("offer_card.h2")} :{" "}
                            {moment(offer?.startDate).format("ll")}
                          </p>
                          <strong className="subheaderText text-left m-0">
                            {t("offer_card.h3")}: {offer?.pointPrice}
                          </strong>
                        </div>
                        <div
                          className="darkView"
                          style={{
                            backgroundColor:
                              offer?.pointNeeded === "Debit" &&
                              "rgb(252 85 84)",
                          }}
                        >
                          <div style={{ justifyContent: "space-between" }}>
                            <p className="tillText text-left">
                              {t("offer_card.h4")}
                            </p>
                            <p className="validText">
                              {moment(offer?.validDate).format("ll")}
                            </p>
                          </div>
                          <div
                            style={{
                              width: 44,
                              height: 44,
                              backgroundColor: "white",
                              borderRadius: 22,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              className="cutleryImage"
                              src={CutleryIcon}
                              alt="cutlery"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {offerData?.data?.length === 0 && !isLoading && (
                      <h4 className="text-center mt-4">
                        {t("common.empty_table")}
                      </h4>
                    )}
                  </>
                ) : (
                  <>
                    {offerData?.data?.map((item, index) => (
                      <CouponCard
                        key={index}
                        data={item}
                        fun={handleVerifyCoupon}
                      />
                    ))}
                    {offerData?.data?.length === 0 && !isLoading && (
                      <h5 className="text-center mt-4">
                        {t("common.empty_table")}
                      </h5>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <div>
            {offerData?.message === "expired" && (
              <>
                <Lottie
                  style={{
                    height: "150px",
                    paddingBottom: "0",
                  }}
                  animationData={clockAnimation}
                />
                <p className="pt-2"> {t("coupon_management.expire_Time")}</p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CouponVerificationManagement;
