import React, { useEffect, useState } from "react";
import CutleryIcon from "../../images/shoppingBag.png";
import moment from "moment";
import AddOffers from "../modal/AddOffers";
import toast from "react-hot-toast";
import {
  getOfferApi,
  addOfferApi,
  editOfferApi,
  deleteOfferApi,
} from "../../services/Store/StoreServices";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import OffersPageSkeleton from "../components/SkeletonPages/OffersPageSkeleton";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../modal/ConfirmationModal";

function LoyaltyComponent(props) {
  const programId = props?.location?.state?.id;
  const [addOfferModal, setAddOfferModal] = useState(false);
  const [editOfferModal, setEditOfferModal] = useState(false);
  const [offerItem, setOfferItem] = useState(null);
  const [offersList, setOfferList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation();

  async function getOffersListing() {
    setLoader(true);
    try {
      const response = await getOfferApi(programId);
      setOfferList(response?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    getOffersListing();
  }, [programId]);

  return (
    <>
      <div className="d-flex justify-content-end align-items-center">
        <h3></h3>
        <div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setAddOfferModal(true)}
          >
            {t("common.add_offer")}
          </button>
        </div>
      </div>
      {offersList?.map((item) => (
        <>
          {loader ? (
            <OffersPageSkeleton count={2} />
          ) : (
            <div
              className="loyaltyItem mt-3"
              key={item._id}
              style={{
                backgroundColor: item?.pointNeeded === "Debit" && "#fee6ea",
              }}
            >
              <div className="d-flex justify-content-between">
                <h2 className="headerText m-0">{item?.title}</h2>
                <Dropdown>
                  <Dropdown.Toggle className="offer-action-btn">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item className="offer-acton-options">
                      <div
                        class="btn btn-primary shadow btn-xs sharp list-btn"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEditOfferModal(true);
                          setOfferItem(item);
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />{" "}
                      </div>
                      <div
                        class="btn btn-danger  btn-xs sharp list-btn"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "red",
                          color: "white",
                        }}
                        onClick={() => {
                          setDeleteModal(true);
                          setOfferItem(item);
                        }}
                      >
                        <i class="fa fa-trash"></i>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex justify-content-between">
                <p className="subheaderText text-left pb-0">
                  Times Per User: {item?.oncePerUser}
                </p>
                <p className="subheaderText text-left m-0">{item?.tier}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="subheaderText text-left m-0">
                  Valid from : {moment(item?.startDate).format("ll")}
                </p>
                <p className="subheaderText text-left m-0">
                  Price: {item?.pointPrice}
                </p>
              </div>
              <div
                className="darkView"
                style={{
                  backgroundColor:
                    item?.pointNeeded === "Debit" && "rgb(252 85 84)",
                }}
              >
                <div style={{ justifyContent: "space-between" }}>
                  <p className="tillText text-left">Valid Till</p>
                  <p className="validText">
                    {moment(item?.validDate).format("ll")}
                  </p>
                </div>
                <div
                  style={{
                    width: 44,
                    height: 44,
                    backgroundColor: "white",
                    borderRadius: 22,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="cutleryImage"
                    src={CutleryIcon}
                    alt="cutlery"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ))}
      {!offersList.length > 0 && !loader && (
        <div className="d-flex justify-content-center">
          <h3>{t("common.empty_table")}</h3>
        </div>
      )}
      {addOfferModal && (
        <AddOffers
          title={t("common.add_offer")}
          successMessage={t("toast_messages.add_offer_success_message")}
          show={addOfferModal}
          onHide={() => setAddOfferModal(false)}
          api={addOfferApi}
          refresh={getOffersListing}
          programId={programId}
        />
      )}
      {editOfferModal && (
        <AddOffers
          title={t("common.edit_offer")}
          successMessage={t("toast_messages.edit_offer_success_message")}
          show={editOfferModal}
          onHide={() => setEditOfferModal(false)}
          api={editOfferApi}
          refresh={getOffersListing}
          programId={programId}
          item={offerItem}
        />
      )}
      {deleteModal && (
        <ConfirmationModal
          data={offerItem}
          api={deleteOfferApi}
          successMessage={"Offer Deleted Successfully"}
          refresh={getOffersListing}
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          content={"Are you sure you want to delete this offer?"}
        />
      )}
    </>
  );
}

export default LoyaltyComponent;
