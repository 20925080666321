import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { getItemsApi } from "../../services/Store/StoreServices";
import { useTranslation } from "react-i18next";

const CreateItemModal = ({
  show,
  handleClose,
  api,
  successMessage,
  storeId,
}) => {
  const [itemName, setItemName] = useState("");
  const [pointValue, setPointValue] = useState("");
  const [nameError, setNameError] = useState("");
  const [pointValueError, setPointValueError] = useState("");
  const [loader, setLoader] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (itemName.trim() === "") {
      setNameError("Item value is required");
      return;
    }
    if (pointValue.trim() === "") {
      setPointValueError("Points is required");
      return;
    }
    setLoader(true);
    try {
      const postData = {
        name: itemName,
        points: pointValue,
      };
      const response = await api(postData);
      toast.success(successMessage);
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  async function handleGetItems() {
    setLoader(true);
    try {
      const res = await getItemsApi();
      setItemsData(res?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    handleGetItems();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("create_loyalty_item_form.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="text"
              placeholder={t("create_loyalty_item_form.placeholder_one")}
              className="form-control"
              value={itemName}
              onChange={(e) => {
                setItemName(e.target.value);
                setNameError("");
              }}
            />
            <span className="text-danger">{nameError}</span>
          </div>
          <div className="mb-3">
            <input
              type="number"
              placeholder={t("create_loyalty_item_form.placeholder_second")}
              className="form-control"
              onChange={(e) => {
                setPointValue(e.target.value);
                setPointValueError("");
              }}
            />
            <span className="text-danger">{pointValueError}</span>
          </div>
          <Button variant="primary" type="submit" className="w-100">
            {loader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              `${t("create_loyalty_item_form.button_name")}`
            )}
          </Button>
        </form>

        <div className="pt-3">
          <h4>{t("create_loyalty_item_form.item_list_title")}</h4>

          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            {itemsData?.items?.map((item) => (
              <div className="items-list">
                <p>{item?.name}</p>
                <p>{item?.points}</p>
              </div>
            ))}
            {itemsData?.items?.length === 0 && (
              <span>{t("common.empty_table")}</span>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateItemModal;
