import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { getAccessCodeApi } from "../../services/Store/StoreServices";

const CodeGenerate = ({ show, handleClose, api, successMessage }) => {
  const [name, setName] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [accessData, setAccessData] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await api();
      toast.success(successMessage);
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  async function handleAccessCode() {
    setLoader(true);
    try {
      const res = await getAccessCodeApi();
      setAccessData(res?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }
  console.log(accessData);

  // useEffect(() => {
  //   handleAccessCode();
  // }, []);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Generate Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Enter item name"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              placeholder="Enter item point"
              className="form-control"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
            />
          </div>

          {/* <div className="pt-3">
            <h4>Items Created Already</h4>

            <div style={{ maxHeight: "200px", overflow: "auto" }}>
              {itemsData?.items?.map((item) => (
                <div className="items-list">
                  <p>{item?.name}</p>
                  <p>{item?.points}</p>
                </div>
              ))}
            </div>
          </div> */}
          <Button variant="primary" type="submit" className="w-100">
            {loader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Generate Code"
            )}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CodeGenerate;
